import script from "./ExtraWarningBox.functional.vue?vue&type=script&setup=true&lang=js"
export * from "./ExtraWarningBox.functional.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBanner,QIcon});
