<template>
  <QFile ref="qFileInput" borderless class="extra-qfile-btn" :accept="$props.accept" v-bind="$attrs">
    <slot />
  </QFile>
</template>

<script setup>
  import { ref } from "vue";

  const qFileInput = ref(null);

  defineProps({
    accept: {
      type: String,
      default: ".jpg, .png, image/*",
    },
  });
  defineExpose({ qFileInput });
</script>

<style lang="scss">
  .extra-qfile-btn {
    // you need to specify a width in inline-style in order to avoid weird behaviour
    cursor: pointer;
    display: inline-flex;

    .q-field__native {
      width: 0; // quasar override to remove the input field
    }
  }
</style>
