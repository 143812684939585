import script from "./ExtraTimelineStep.component.vue?vue&type=script&setup=true&lang=js"
export * from "./ExtraTimelineStep.component.vue?vue&type=script&setup=true&lang=js"

import "./ExtraTimelineStep.component.vue?vue&type=style&index=0&id=6ea641a0&lang=scss"

const __exports__ = script;

export default __exports__
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QTimelineEntry,QBtn});
