import { JavascriptHelpers } from "@core/helpers";

// how to write a plugin: https://v3.vuejs.org/guide/plugins.html#installing-plugins

export default {
  install(app) {
    app.config.globalProperties.$filters = {
      capitalizeFirstLetter(value) {
        return JavascriptHelpers.capitalizeFirstLetter(value);
      },
      whitespacedPunctuation(value) {
        return JavascriptHelpers.insertWhitespaceBeforePunctuation(value);
      },
      formLabelValidation(value) {
        return JavascriptHelpers.formatFormLabelValidation(value);
      },
    };
  },
};
