// fonts
import "@core/styles/fonts";
// @core plugins
import configureInternationalization from "@core/plugins/i18n";
import FiltersPlugin from "@core/plugins/filters";
import { qLoadingMixin } from "@core/mixins/q-loading.mixin";
import DesignSystemPlugin from "@core/plugins/design-system";
// i18n
import specificLocales from "@extracadabra/i18n/fr-FR/jobs.json";

export default ({ app, router }) => {
  const i18n = configureInternationalization([{ locale: "fr", messages: specificLocales }]);
  app.use(FiltersPlugin);
  app.use(DesignSystemPlugin);
  app.mixin(qLoadingMixin);
  app.use(i18n);
};
