<template>
  <div class="footer-btns-wrapper">
    <slot />
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss">
  .footer-btns-wrapper {
    button {
      border-radius: 0;
      min-height: 75px;
      color: white;
      font-size: 20px;
    }
  }
</style>
