import { reactive } from "vue";
import { VISUAL_LOADING_DELAY } from "@core/constants";

const isSSR = process.env.SERVER === true;

// we create a reactive state to manipulate inside the LoadingManager
// in order to track and display changes in components and pages
const state = reactive({
  isLoading: false,
  message: undefined,
});

let timeout;

export class LoadingManager {
  static isActive() {
    return state.isLoading;
  }

  static displayMessage() {
    return state.message;
  }

  static show({ message = "", delay = VISUAL_LOADING_DELAY } = {}) {
    if (isSSR) {
      return; // no loading for SSR
    }
    clearTimeout(timeout);
    // we allow for a delay in order to prevent showing a flashing loading screen on fast connexion
    timeout = setTimeout(() => {
      window.document.body.classList.add("no-scroll"); // prevent scroll on body during loading
      state.isLoading = true;
      state.message = message;
    }, delay);
  }

  static hide() {
    if (isSSR) {
      return; // no loading for SSR
    }
    if (timeout !== undefined) {
      clearTimeout(timeout);
      timeout = undefined;
    }
    window.document.body.classList.remove("no-scroll");
    state.isLoading = false;
    state.message = undefined;
  }
}
