<template>
  <QBtn :class="['extra-btn-icon', $attrs.class]" v-bind="$attrs">
    <!-- props.allSpace take all space if class="all-width" is set -->
    <div class="extra-btn-icon-content" :class="{ 'take-all-space': allSpace }">
      <QIcon name="fa fa-memo-circle-info" />
      <p v-if="btnLabel">
        {{ btnLabel }}
      </p>
      <QIcon v-if="shouldDisplayIcon" :color="iconColor" :size="iconSize" :name="iconName" />
    </div>
  </QBtn>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    btnLabel: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    iconSize: {
      type: String,
      default: "",
    },
    iconToTheRight: {
      type: Boolean,
      default: false,
    },
    allSpace: {
      type: Boolean,
      default: false,
    },
  });

  const shouldDisplayIcon = computed(() => {
    return props.iconName && props.iconName !== "" && !props.iconToTheRight;
  });
</script>

<style lang="scss" scoped>
  .extra-btn-icon {
    min-height: 48px;
    .extra-btn-icon-content {
      display: flex;
      align-items: center;
      padding: 0 15px;

      &.take-all-space {
        width: 100%;
      }

      p {
        line-height: 1.2;
        margin: 0 15px;
        flex: 1;
      }
    }
  }
</style>
