import script from "./ExtraExpansionTip.functional.vue?vue&type=script&setup=true&lang=js"
export * from "./ExtraExpansionTip.functional.vue?vue&type=script&setup=true&lang=js"

import "./ExtraExpansionTip.functional.vue?vue&type=style&index=0&id=496edcd4&lang=scss"

const __exports__ = script;

export default __exports__
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QExpansionItem,QCard,QCardSection});
