import script from "./ExtraTipbox.functional.vue?vue&type=script&setup=true&lang=js"
export * from "./ExtraTipbox.functional.vue?vue&type=script&setup=true&lang=js"

import "./ExtraTipbox.functional.vue?vue&type=style&index=0&id=2767a150&lang=scss"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
