import script from "./ExtraChip.functional.vue?vue&type=script&setup=true&lang=js"
export * from "./ExtraChip.functional.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QChip,QAvatar,QIcon});
