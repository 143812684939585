<template>
  <div :class="['extra-tipbox', icon ? 'extra-tipbox-with-side-icon' : '', `text-${color}`, `bg-${background}`, $attrs.class]" v-bind="$attrs">
    <section class="extra-tipbox-side" :class="extraTipboxSideClassname">
      <template v-if="icon">
        <span class="extra-tipbox-side-icon">
          <QIcon :name="icon" color="primary" size="24px" />
        </span>
      </template>
      <template v-else>
        <img :src="logo" alt="extra-tipbox-logo" :draggable="false" />
      </template>
    </section>
    <section class="extra-tipbox-content">
      <div>
        <slot />
      </div>
    </section>
  </div>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    icon: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: () => require("@core/assets/img/logo_tipbox.png"),
    },
    sideImg: {
      type: Boolean,
      default: false,
    },
    sideColor: {
      type: String,
      default: "primary",
    },
    color: {
      type: String,
      default: "primary",
    },
    background: {
      type: String,
      default: "white",
    },
  });

  const extraTipboxSideClassname = computed(() => {
    return props.sideImg ? "extra-tipbox-with-side-icon" : `bg-${props.sideColor}`;
  });
</script>

<style lang="scss">
  .extra-tipbox {
    min-height: 80px;
    box-shadow: $card-shadow;
    background: white;
    border-radius: $generic-border-radius;
    display: flex;
    overflow: hidden;

    .extra-tipbox-side,
    .extra-tipbox-content {
      padding: 8px 12px;
    }

    .extra-tipbox-side {
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $generic-border-radius 0 0 $generic-border-radius;

      > img {
        width: 32px;
      }
    }

    &.extra-tipbox-with-side-icon {
      .extra-tipbox-side {
        width: 86px;
        > .extra-tipbox-side-icon {
          width: 50px;
          text-align: center;
          line-height: 50px;
          background: white;
          border-radius: 50%;
        }
      }
    }

    .extra-tipbox-img-side {
      background: url("~@core/assets/img/photo_tipbox.png") !important;
    }

    .extra-tipbox-content {
      display: flex;
      align-items: center;
      justify-content: left;
      flex: 1;

      a {
        cursor: pointer;
        i {
          margin-right: 5px;
        }
        span {
          text-decoration: underline;
          margin-right: 10px;
        }

        @media screen and (max-width: 320px) {
          font-size: 0.9em;
        }
      }
    }
  }
</style>
