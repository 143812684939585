<template>
  <QChip :clickable="!props.unSelectable && true" :style="chipStyle" :color="chipColor" text-color="white" v-bind="$attrs">
    <QAvatar v-if="isSelected" color="primary" text-color="white">
      <QIcon :name="iconName" />
    </QAvatar>
    <slot />
  </QChip>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    unSelectable: {
      type: Boolean,
      default: false,
    },
    unselectedColor: {
      type: String,
      default: "grey",
    },
    selectedColor: {
      type: String,
      default: "primary-medium",
    },
    iconName: {
      type: String,
      default: "done",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  });

  const chipColor = computed(() => {
    return props.isSelected ? props.selectedColor : props.unselectedColor;
  });

  const chipStyle = computed(() => {
    return props.isSelected ? "padding-left:12px; padding-right:13px;" : "padding-left:23px; padding-right:23px;";
  });
</script>

<style></style>
