<template>
  <img
    :src="picture"
    prevent-selection
    class="extra-avatar"
    alt="extra-avatar"
    :draggable="false"
    :style="{ maxWidth: getSize(), height: getSize() }"
    @error="onImg403"
    @click="$emit('click')"
  />
</template>

<script>
  const placeholderImg = require("@core/assets/img/user_photo_placeholder.svg");
  const placeholderWhiteImg = require("@core/assets/img/user_photo_placeholder_white.svg");
  const placeholderPrimaryImg = require("@core/assets/img/user_photo_placeholder_blue.svg");

  export default {
    props: {
      picture: {
        type: String,
        default: "",
      },
      size: {
        type: String,
        default: "115px",
      },
      white: {
        type: Boolean,
        default: false,
      },
      primary: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["onImg403", "click"],
    methods: {
      onImg403(event) {
        if (this.white) {
          event.target.src = placeholderWhiteImg;
        } else if (this.primary) {
          event.target.src = placeholderPrimaryImg;
        } else {
          event.target.src = placeholderImg;
        }
        this.$emit("onImg403");
      },
      getSize() {
        return this.size;
      },
    },
  };
</script>

<style lang="scss">
  .extra-avatar {
    border-radius: 50%;
    box-shadow: $img-shadow;
  }
</style>
