import script from "./IosCheckbox.functional.vue?vue&type=script&setup=true&lang=js"
export * from "./IosCheckbox.functional.vue?vue&type=script&setup=true&lang=js"

import "./IosCheckbox.functional.vue?vue&type=style&index=0&id=a1303a04&lang=scss"

const __exports__ = script;

export default __exports__
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCheckbox});
