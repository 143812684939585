import { LoadingManager } from "@core/managers/loading.manager";

export const qLoadingMixin = {
  computed: {
    $_isGlobalLoadingActive() {
      return LoadingManager.isActive();
    },
  },
  methods: {
    $_startGlobalLoading({ message = "", delay = undefined } = {}) {
      LoadingManager.show({ message, delay });
    },
    $_stopGlobalLoading() {
      LoadingManager.hide();
    },
  },
};
