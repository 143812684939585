<template>
  <div class="extra-icon-label" v-bind="$attrs">
    <QBtn class="full-width" :flat="isBtnFlat" :color="color || background" :ripple="ripple" no-caps :dense="noPadding" :align="align">
      <section :class="[`text-${align}`]">
        <span v-if="iconRight">
          {{ label }}
        </span>
        <QIcon :name="icon" :color="iconColor" :size="iconSize" />
        <span v-if="!iconRight">
          {{ label }}
        </span>
      </section>
    </QBtn>
  </div>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    iconSize: {
      type: String,
      default: "30px",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    ripple: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "left",
    },
  });
  const isBtnFlat = computed(() => {
    return props.background === "" ? true : false;
  });
</script>

<style lang="scss">
  .extra-icon-label {
    span {
      line-height: 1.2em;
    }

    &:not([clickable]) {
      pointer-events: none;
    }

    &[heavy] {
      span {
        font-family: $common-font-family;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .q-icon:first-child {
      margin-right: 25px;
    }

    .q-icon:last-child {
      margin-left: 10px;
    }

    section {
      display: flex;
      align-items: center;
    }
  }
</style>
