import script from "./IconLabel.functional.vue?vue&type=script&setup=true&lang=js"
export * from "./IconLabel.functional.vue?vue&type=script&setup=true&lang=js"

import "./IconLabel.functional.vue?vue&type=style&index=0&id=de254692&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon});
