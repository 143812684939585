<template>
  <div class="extra-icon-box-wrapper" v-bind="$attrs">
    <div v-if="!hideIcon" class="left-icon" :class="leftIconClassname">
      <QIcon :name="icon" :size="iconSize" :color="iconColor" />
    </div>
    <slot />
  </div>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    icon: {
      type: String,
      default: "",
    },
    iconSize: {
      type: String,
      default: "20px",
    },
    iconColor: {
      type: String,
      default: "primary-light",
    },
    iconBackground: {
      type: String,
      default: "",
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  });

  const leftIconClassname = computed(() => {
    return props.iconBackground ? `bg-${props.iconBackground}` : "bg-primary-ultra-light";
  });
</script>

<style lang="scss">
  .extra-icon-box-wrapper {
    border: solid 1px #eff1f4;
    background-color: white;
    display: flex;
    justify-content: left;

    &[borderless] {
      border: none;
    }

    &[radius] {
      border-radius: 4px;

      .left-icon {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &[shadow] {
      box-shadow: $hard-shadow;
    }

    .left-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      padding: 10px;
    }

    > *:not(.left-icon) {
      min-width: 0; // mandatory if we want to ellipsis inside slot
      flex: 1;
    }
  }
</style>
