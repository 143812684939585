<template>
  <!-- FYI listeners binding doesn't work for native events, you need to bind those manually
          @click.native="(listeners.click ? listeners.click(): ()=> {})" -->
  <QCheckbox class="ios-checkbox" :size="checkboxSize" v-bind="$attrs" />
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    size: {
      type: String,
      default: "",
    },
  });

  const checkboxSize = computed(() => {
    return props.size === "sm" ? "30px" : "38px";
  });
</script>

<style lang="scss">
  .q-checkbox.ios-checkbox {
    .q-checkbox__label {
      margin: 0 5px;
    }
    .q-checkbox__bg {
      border-radius: 50%;
      top: 10%;
      left: 10%;
      width: 75%;
      height: 75%;

      > svg {
        width: 65% !important;
        left: 18%;
      }
    }
  }
</style>
