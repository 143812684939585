<template>
  <div v-if="LoadingManager.isActive()" class="loading-spinner-in-progress">
    <section class="text-center q-gutter-md">
      <QSpinner color="white" size="5em" :thickness="5" />
      <h4>{{ LoadingManager.displayMessage() }}</h4>
    </section>
  </div>
</template>

<script>
  import { LoadingManager } from "@core/managers/loading.manager";

  export default {
    created() {
      this.LoadingManager = LoadingManager;
    },
  };
</script>

<style lang="scss">
  .loading-spinner-in-progress {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 9999;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
</style>
