<template>
  <div class="extra-textarea" v-bind="$attrs">
    <label v-if="label" class="form-label" :for="id">{{ label }}</label>
    <textarea
      :id="id"
      :class="`bg-${background || 'grey-2'}`"
      :name="id"
      :placeholder="placeholder"
      :value="modelValue"
      :style="{ height: height }"
      @input="updateValue($event.target.value)"
    />
    <section v-if="limit" class="extra-textarea-helper" :class="{ error: modelValue.length > limit }">
      {{ modelValue.length || 0 }} / {{ limit }}
    </section>
  </div>
</template>

<script setup>
  const props = defineProps({
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 0,
    },
    background: {
      type: String,
      default: "grey-2",
    },
    height: {
      type: String,
      default: "auto",
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const updateValue = (value) => {
    emit("update:modelValue", value);
  };
</script>

<style lang="scss">
  .extra-textarea {
    textarea {
      margin: 0;
      border-radius: 2px;
      width: 100%;
      border: none;
      resize: none;
      outline: none;
      padding: 10px;
      transition: all 0.3s ease;
      min-height: 150px;

      &::placeholder {
        font-family: $common-font-family;
        font-size: 0.95em;
        color: #adadad;
      }
    }

    .extra-textarea-helper {
      margin: 0;
      text-align: right;
      font-size: 0.8em;
      opacity: 0.85;

      &.error {
        font-weight: bold;
        color: $negative;
      }
    }
  }
</style>
