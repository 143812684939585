/**
 * This file is tasked to discover all components and export them as an array
 * /!\ ATTENTION /!\
 * /!\ ATTENTION /!\ You need to import Quasar before this file in order for the DesignSystem to work
 * /!\ ATTENTION /!\
 */

/**
 * @typedef DesignSystemComponent
 * @type {Object}
 * @property {string} name - the name of the component <myDesignSystemComponent /> or <my-global-component />
 * @property {VueComponent} config - the actual Vue component
 */

/**
 * @param {Array.<DesignSystemComponent>} DesignSystemComponents List of discovered global components
 */
const DesignSystemComponents = [];

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the current directory
  ".",
  // Do not look in subdirectories
  false,
  // Only include .vue files
  /\.(vue)$/,
);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Get the PascalCase version of the component name
  const componentName = fileName
    // Remove the "./" from the beginning
    .replace(/^\.\//, "")
    // Remove the component type (.functional, .component)
    .replace(/\.\w+$/, "")
    // Remove the file extension
    .replace(/\.\w+$/, "");

  // Globally register the component
  DesignSystemComponents.push({
    name: componentName,
    config: componentConfig.default || componentConfig,
  });
});

export default DesignSystemComponents;
