import script from "./IosToggle.functional.vue?vue&type=script&setup=true&lang=js"
export * from "./IosToggle.functional.vue?vue&type=script&setup=true&lang=js"

import "./IosToggle.functional.vue?vue&type=style&index=0&id=b8731eca&lang=scss"

const __exports__ = script;

export default __exports__
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QToggle});
